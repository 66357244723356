@import '../../../../assets/style/scss/themes-var.module';
@import '../../../../assets/variable/fontsize.scss';

.viewContent {
  width: 100%;
  min-height: #{$size500}px;
  margin: #{$size34}px 0;
  border-radius: #{$size6}px;
  min-height: #{$size319}px;

}

.content-container {
  height: 100%;
  background-color: $paper;
  padding: #{$size32}px #{$size24}px;
  display: flex;
  border: #{$size2}px solid $variableLight;
  border-radius: #{$size6}px;
}
.content-left {
  width: 15%;
}
.content-right {
  width: 85%;
  max-height: #{$size335}px;
}
.content-right-box {
  padding-bottom: #{$size20}px;
}
.content-right-box h5 {
  padding: #{$size5}px 0;
}
.imgView {
  width: #{$size110}px;
  height: #{$size110}px;
  border-radius: #{$size200}px;
  object-fit: cover;
  border-radius: 50%;
}
.style-title-view {
  color: $variableButtonDropdown;
  font-size: #{$size14}px;
}
.style-data-view {
  color: $variableBtnMenuNav;
  font-size: #{$size14}px;
  word-break: break-word;
}
