@import '../../assets/style/scss/themes-var.module';
@import '../../assets/variable/fontsize.scss';

.session-expired {
    text-align: center;
    background-color: $variableBackgroundStep;
    height: #{$size100}vh;
    width: #{$size100}vw;
    padding: #{$size20}px;
    box-sizing: border-box;
    padding-top: #{$size32}px;
}

.session-expired-container {
    max-width: #{$size550}px;
    padding: #{$size60}px;
    border: #{$size1}px solid $darkPrimaryLight;
    border-radius: #{$size15}px;
    box-shadow: #{$size10}px #{$size10}px #{$size5}px $darkBackground;
    margin: #{$size100}px auto;
    background-color: $paper;
  }

  .session-expired-header {
    margin: #{$size30}px 0 #{$size15}px 0;
  }

  .session-expired-text {
    max-width: #{$size350}px;
    margin: auto;
    margin-bottom: #{$size30}px;
  }

  .session-expired-button {
    text-transform: none;
    border-radius: #{$size30}px;
    padding: #{$size8}px #{$size25}px;
    background-color: $variableOrange;
    color: $paper;
  }
  
  .session-expired-button:hover {
    background-color:$warningDark
  }