@import '../../../../assets/style/scss/themes-var.module.scss';
@import '../../../../assets/variable/fontsize.scss';

.project {
  width: 100%;
  margin: #{$size24}px 0;

  &-information {
    padding: #{$size32}px #{$size24}px;
    background-color: $paper;
    border-radius: #{$size6}px;
    border: #{$size2}px solid $variableLight;

    &-box {
      width: 100%;

      &-grid {
        padding-top: #{$size15}px;
      }
    }
  }
  &-manage {
    margin-top: #{$size30}px;
    padding: #{$size32}px #{$size24}px;
    background-color: $paper;
    border-radius: #{$size6}px;
    border: #{$size2}px solid $variableLight;

    &-member {
      margin-top: #{$size30}px;

      &-boxSelect {
        display: flex;
        justify-content: space-between;
        margin: #{$size16}px 0;
      }

      &-buttonAddMember {
        background-color: $variableBackgroundStep;
        color: $paper;
        font-size: #{$size14}px;
        font-weight: $fontWeigth700;
        padding: #{$size8}px #{$size15}px;
        margin-top: #{$size15}px;
        text-transform: capitalize;
        &:hover {
          background-color: $variableOrange;
        }
      }
    }
  }
}

.box-button {
  display: flex;
  justify-content: flex-end;

  &-cancel {
    width: #{$size75}px;
    height: #{$size33}px;
    border: #{$size1}px solid $variableButtonDropdown;
    color: $variableButtonDropdown;
    margin-right: #{$size25}px;
    border-radius: #{$size5}px;
    font-size: #{$size14}px;
    font-weight: $fontWeigth700;
    text-transform: capitalize;
    text-decoration: none;
  }

  &-save {
    width: #{$size62}px;
    height: #{$size33}px;
    background: $variableBackgroundStep;
    color: $paper;
    font-weight: $fontWeigth700;
    font-size: #{$size14}px;
    border-radius: #{$size5}px;
    text-transform: capitalize;

    &:hover {
      background-color: $variableBackgroundStep;
    }
  }
}

.style-typography-h2 {
  font-size: #{$size18}px;
  font-weight: $fontWeigth700;
}
