@import '../../../assets/style/scss/themes-var.module';
@import '../../../assets/variable/fontsize.scss';
.project-row-item:last-child .project-cell-item {
  border-bottom: none;
}

.user-cell-item {
  text-align: justify;
  &:first-child {
    text-align: left;
    padding-left: #{$size20}px;
  }
}
.user-cell-avatar-item {
  text-align: justify;
  width: #{$size40}px;
}

.project-cell-item {
  text-align: justify;
  &:first-child {
    text-align: left;
    padding-left: #{$size20}px;
  }
  &:nth-child(3) {
    padding-left: 3%;
  }
  &:last-child {
    text-align: right;
  }
}

.boxTitle {
  font-size: #{$size14}px;
  padding: #{$size3}px 0px #{$size3}px #{$size16}px;
}

.project-row-item:last-child .modalActionClick {
  top: -90px;
}
.stylelinkView {
  display: flex;
  color: $variableBlack;
  text-decoration: none;
}
.style-border-chip {
  border-radius: #{$size8}px;
  margin-top: #{$size6}px;
  margin-bottom: #{$size6}px;
}

.view-icon {
  color: $variableDarkGrey;
  &:hover {
    color: $variableBlack
  }
}
