@import '../../assets/style/scss/themes-var.module';
@import '../../assets/variable/fontsize.scss';

.button {
  background-color: $variableOrange;
  color: $paper;
  font-weight: $fontWeigth700;
  &:hover {
    background-color: $variableOrange;
  }
}