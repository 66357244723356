@import '../../assets/variable/fontsize.scss';
@import 'assets/style/scss/themes-var.module';

.dashboard {
  display: flex;
  justify-content: space-between;
}
.dashboard-left {
  width: 66%;
}
.dashboard-right {
  width: 31%;
  padding-top: #{$size45}px;
}
.dashboard-welcome-text {
  padding-bottom: #{$size12}px;
  font-size: 1.4rem;
  font-weight: #{$fontWeigth600};
}
.dashboard-paper {
  box-shadow: none;
  border: #{$size2}px solid $grey350;
  margin-bottom: #{$size45}px;
}
.dashboard-my-task {
  background-color: $paper;
  margin-top: #{$size28}px;
  border-radius: #{$size4}px;
  border: #{$size1}px solid $grey300;
}
.dashboard-my-task-text {
  padding-left: #{$size8}px;
  font-weight: #{$fontWeigth600};
}
.dashboard-my-user-event {
  min-height: #{$size150}px;
}
.dashboard-out-of-office {
  min-height: #{$size150}px;
  margin-bottom: #{$size20}px;
}
.dashboard-my-title {
  display: flex;
  align-items: center;
  height: #{$size50}px;
  border-bottom: #{$size1}px solid $grey300;
  padding-left: #{$size18}px;
}
.dashboard-my-event {
  background-color: $paper;
  width: #{$size335}px;
  margin-top: #{$size28}px;
  border-radius: #{$size4}px;
  padding: 0;
  border: #{$size1}px solid $grey300;
}
.dashboard-event-text {
  font-weight: #{$fontWeigth600};
  font-size: #{$size14}px;
  text-align: center;
  padding-top: #{$size55}px;
  color: $grey400;
}
.dashboard-boxs {
  padding-left: #{$size10}px;
}
.dashboard-comming-leave {
  border-bottom: #{$size1}px solid $grey300;
  height: #{$size50}px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.dashboard-comming-leave-button {
  background-color: $variableBackgroundStep;
  color: $paper;
  border-radius: #{$size4}px;
  font-weight: #{$fontWeigth700};
  padding: 0 #{$size8}px;
  height: #{$size30}px;
  margin-right: #{$size32}px;
}
.dashboard-comming-leave-text {
  padding-left: #{$size8}px;
  font-weight: #{$fontWeigth600};
}
.dashboard-item-container {
  padding-bottom: #{$size16}px;
}

.dashboard-button {
    margin-left:  #{$size24}px;
    margin-top:  #{$size24}px;
    padding: #{$size4}px #{$size15}px  #{$size5}px;
    background-color: $variableColorDelete;
    border-radius: #{$size5}px;
    letter-spacing: 0.01em;
    font-weight: #{$fontWeigth700};
    font-size: #{$size14}px;
    text-transform: capitalize;
    color: $paper
}
.dashboard-avatar{
    margin-left: #{$size24}px;;
    margin-top: #{$size24}px;;
}
.dashboard-text{
    letter-spacing: 0.01em;
    font-weight: #{$fontWeigth700};
    font-size: #{$size14}px;
    text-transform: capitalize;
    display: flex;
    flex-direction: column;
    justify-content: center;
}
.dashboard-no-upcoming-leave-text{
  font-weight: #{$fontWeigth400};
  font-size: #{$size14}px;
  text-align: center;
  color: $grey400;
}
.dashboard-button:hover{
    background-color: $variableColorDelete;
}
.dashboard-account{
    display: flex;
}
.dashboard-user{
    color: $variableBtnMenuNav;
    font-size: #{$size14}px;
    font-weight: #{$fontWeigth700};
    letter-spacing: 0.01em;
    margin-left: #{$size12}px;
    margin-top: #{$size34}px;
}
.dashboard-button-other{
    margin-left: #{$size24}px;;
    margin-top: #{$size24}px;
    padding: #{$size4}px #{$size15}px  #{$size5}px;
    background-color: $variableBackgroundStep;
    letter-spacing: 0.01em;
    font-weight: #{$fontWeigth700};
    font-size: #{$size14}px;
    text-transform: capitalize;
    border-radius: #{$size5}px;
    color: $paper
}
.dashboard-button-other:hover{
    background-color: $variableBackgroundStep;
}