@import '../../../../src/assets/variable/fontsize.scss';
@import '../../../../src/assets/style/scss/themes-var.module';

.forgot-password-base-container {
  background-image: url('../../../assets/img/BackGround.png');
  height: #{$size100}vh;
  display: flex;
  background-repeat: no-repeat;
  background-size: cover;
}

.column-left {
  flex: 1;
  background-color: $paper;
}

.column-right {
  flex: 1;
}

.forgot-password-base-content {
  margin-top: 6%;
}

.icon-vatek-left-wrapper {
  margin-bottom: #{$size65}px;
  text-align: center;
}

.icon-vatek-left {
  width: #{$size140}px;
  fill: $variableBackgroundStep;
}

.copyright {
  position: fixed;
  width: 50%;
  bottom: #{$size12}px;
  z-index: 10;
  text-align: center;
  margin: 0 auto;
  &-text {
    font-size: #{$size14}px;
    font-weight: $fontWeigth400;
    color: $variableButtonDropdown;
  }
}

.icon-vatek-right-wrapper {
  padding: #{$size30}px #{$size30}px 0 0;
  text-align: end;
}

.icon-vatek-right {
  width: #{$size102}px;
  height: #{$size73}px;
  fill: $paper;
}

.forgot-password-base-banner {
  display: flex;
  justify-content: center;
  align-items: center;
  height: #{$size85}vh;
}

.banner-img {
  width: #{$size462}px;
  height: #{$size567}px;
}
