@import '../../assets/variable/fontsize.scss';
@import 'assets/style/scss/themes-var.module';

.style-title-h2 {
  font-size: #{$size30}px;
  font-weight: $fontWeigth700;
}
.style-content {
  &-change-password {
    background-color: $paper;
    border: #{$size2}px solid $variableLight;
    border-radius: #{$size6}px;
    margin-top: #{$size30}px;
    display: flex;
    min-height: 50vh;
  }
  &-box-input {
    width: 80vh;
    align-self: center;
    margin-left: 5%;
  }
  &-box-button {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
  }

  &-button-submit {
    height: #{$size33}px;
    background: $variableBackgroundStep;
    color: $paper;
    font-weight: $fontWeigth700;
    font-size: #{$size14}px;
    border-radius: #{$size5}px;
    text-transform: capitalize;
    margin-top: #{$size14}px;
    &:hover {
      background-color: $variableBackgroundStep;
    }
    text-align: right;
  }
}
.inputTextfield {
  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  input:-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0 #{$size30}px $paper inset !important;
  }
}
.login-input-props {
  padding-right: #{$size60}px;
}
.login-icon-input {
  position: absolute;
  right: 2%;
}
.container-loading {
  height: #{size500}px;
  display: flex;
  justify-content: center;
  align-self: center;
}
.box-loading {
  width: #{size500}px;
  height: #{size100}px;
  box-shadow: none;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.text-loading {
  color: $paper;
  font-size: #{size16}px;
  margin-top: #{size15}px;
}