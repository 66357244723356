@import '../../../../assets/style/scss/themes-var.module';
@import '../../../../assets/variable/fontsize.scss';

.nav-item {
    padding:  #{$size8}px 0  #{$size8}px  #{$size12}px;

    &:hover {
        color: $variableOrange;
        background-color: $orangeMenu;
        fill: $variableOrange;
        & .nav-item-icon {
            color: $variableOrange;
        }
    }
    &:focus {
        color: $variableOrange;
        background-color: $orangeMenu;
        & .nav-item-icon {
            color: $variableOrange;
        }
    }
    &-icon {
        min-width: #{$size36}px;
        color: $variableBtnMenuNav;
        &-open {
            min-width: #{$size36}px;
            color: $variableOrange;
            background-color: $orangeMenu;
            fill: $variableOrange;
        }
        &:hover{
            color: $variableOrange;
        }
    }
    &-open {
        padding:  #{$size8}px 0  #{$size8}px  #{$size12}px;
        background-color: $orangeMenu;
        color: $variableOrange;
        &:hover{
            background-color: $orangeMenu;
        }
    }
    &-text {
        font-weight: #{$fontWeigth400};
        font-size: #{$size16}px;
    }
}
