@import '../../../../assets/style/scss/themes-var.module';
@import '../../../../assets/variable/fontsize.scss';

.nav-sub {
    margin:  #{$size8}px;
    font-size:  #{$size14}px;
    padding:  0 0  0  #{$size12}px;
    border-radius: #{$size8}px;
    &:hover {
        color: $variableOrange;
        background-color: $orangeMenu;
    }
    &-name {
        font-size:  #{$size14}px;
        padding:  #{$size4}px 0  #{$size4}px  #{$size12}px;
        &-open {
            font-weight: #{$fontWeigth700};
            font-size:  #{$size14}px;
            padding:  #{$size4}px 0  #{$size4}px  #{$size12}px;
        }
    }
    &-open{
        margin:  #{$size8}px;
        padding:  0 0 0  #{$size12}px;
        font-size:  #{$size14}px;
        border-radius: #{$size8}px;
        color: $variableOrange;
        background-color: $orangeMenu;
    }
}

