@import '../../../assets/variable/fontsize.scss';
@import "assets/style/scss/themes-var.module";

.dashboard-my-user{
    height: #{$size62}px;
    margin-top: #{$size16}px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.dashboard-no-leave-text{
    padding-left: #{$size20}px;
    height: #{$size62}px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.dashboard-view-more{
    display: flex;
    justify-content: flex-end;
    margin: #{$size16}px 0;
}
.dashboard-view-more-button{
    text-transform: none;
    font-weight: #{$fontWeigth700};
    color: $variableDarkGrey;
}
.dashboard-user-name{
    font-size: #{$size14}px;
    font-weight: #{$fontWeigth600};
}
.dashboard-user-time{
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-right: #{$size30}px;
}
.dashboard-user-time-text{
    font-size: #{$size14}px;
}
.dashboard-my-task-avatar{
    margin-right: #{$size24}px;
}