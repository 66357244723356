@import 'assets/style/scss/themes-var.module.scss';
@import 'assets/variable/fontsize.scss';

.profileContainer {
  min-width: #{$size132}px;
  min-height: #{$size101}px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background: $paper;
  border: #{$size1}px solid $paper;
  border-radius: #{$size6}px;
  box-shadow: #{$size1}px #{$size1}px #{$size3}px 0 $grey400;
}
.profileContent {
  width: 100%;
}
.profileContent:hover {
  background-color: $grey200;
}
.profile-chip-avatar{
  cursor: pointer;
}
.profileItem {
  display: flex;
  align-items: center;
  padding: #{$size14}px;
  cursor: pointer;
}
