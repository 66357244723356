@import '../../../src/assets/variable/fontsize.scss';
@import '../../../src/assets/style/scss/themes-var.module';

.forgot-password-information {
  margin: 12% 0 8% 0;
  max-width: #{$size475}px;
  margin: 0 auto;
}

.forgot-password-form {
  margin-top: #{$size25}px;
}

.button-submit-form {
  text-transform: capitalize;
  font-size: #{$size14}px;
  font-weight: $fontWeigth700;
  background-color: $variableBackgroundStep;
  margin-top: #{$size16}px;
  &:hover {
    background-color: $variableBackgroundStep;
  }
}

