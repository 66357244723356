@import "../../../../assets/style/scss/themes-var.module";
@import "../../../../assets/variable/fontsize.scss";

.logo-section-box {
    display: flex; 
    justify-content: center ;
    align-items: center;
}
.logo-section-icon {
    padding: #{$size8}px;
}
.logo-section-text {
    font-size: #{$size25}px; 
    margin-left: #{$size3}px;
    color: $paper;
}