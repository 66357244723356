@import '../../../../assets/style/scss/themes-var.module';
@import '../../../../assets/variable/fontsize.scss';

.viewHeader {
  width: 100%;
  height: #{$size36}px;

  display: flex;
  justify-content: space-between;
}

.headerTitle {
  font-size: #{$size30}px;
  font-weight: $fontWeigth700;
  letter-spacing: 1%;
}

.headerButton {
  display: flex;
}

.headerButton button {
  display: flex;
  font-size: #{$size14}px;
  border-radius: #{$size5}px;
  padding: 0 #{$size16}px;
}

.headerButton .offboarding {
  background-color: $errorMain;
  margin-left: #{$size16}px;
  padding: #{$size5}px #{$size16}px;
}

// css modal
.styeBoxModal {
  min-width: #{$size406}px;
  min-height: #{$size187}px;
  text-align: center;
  border-radius: #{$size10}px;
  padding: #{$size15}px #{$size10}px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}
.titleModalDelete {
  font-size: #{$size25}px;
  font-weight: $fontWeigth700;
}
.titleContentDelete {
  line-height: 2;
  padding: 5px;
  max-width: 600px;
  font-size: #{$size16}px;
  font-weight: $fontWeigth500;
}
.styleButtonModal {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
}
.sizeButton {
  min-width: #{$size137}px;
  min-height: #{$size33}px;
  text-transform: none;
  font-size: #{$size14}px;
  font-weight: $fontWeigth700;
}
.styleButtonDelete {
  background-color: $variableColorDelete;
  color: $paper;
  &:hover {
    background-color: $errorMain;
  }
}
.styleButtonCancel {
  border: #{$size1}px solid $variableBtnInactive;
  color: $variableBtnInactive;
  &:hover {
    background-color: $paper;
  }
}
