@import '../../../assets/style/scss/themes-var.module';
@import '../../../assets/variable/fontsize.scss';

.leave-form-header {
    text-align: center;
    border-bottom: #{$size1}px solid $grey300;
}
.leave-form-container {
    margin: #{$size20}px #{$size20}px 0 #{$size20}px;
}
.footerUser {
    display: flex;
    justify-content: flex-end;
    margin:  #{$size20}px #{$size20}px #{$size20}px 0;
}
.btnCancelOrBack{
    color: $variableBlack;
    font-style: normal;
    font-weight: $fontWeigth700;
    font-size: #{$size14}px;
    line-height: #{$size23}px;
    text-align: center;
    letter-spacing: 0.01em;
    text-transform: none;
    border: #{$size1}px solid $variableBlack;
    flex: none;
    order: 0;
    flex-grow: 0;
    margin-right: #{$size16}px;
}
.btnButton{
    background-color: #{$variableBackgroundStep};
    color: #{$paper};
    font-weight: #{$fontWeigth700};
    text-transform: capitalize;
    padding: #{$size5}px, #{$size8}px, #{$size5}px, #{$size8}px;
}
.btnButton:hover{
    background-color: #{$variableBackgroundStep};
}