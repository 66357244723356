@import '../../../../src/assets/variable/fontsize.scss';
@import '../../../../src/assets/style/scss/themes-var.module';

.reset-password-success-information {
  text-align: center;
}

.reset-password-success-img {
  display: flex;
  justify-content: center;
  width: #{$size235}px;
  height: #{$size235}px;
  margin: 0 auto;
}

.login-link {
  color: $variableBackgroundStep;
  text-decoration: none;
  margin-left: #{$size5}px;
  &:hover {
    opacity: 0.8;
  }
}
