@import '../../../../src/assets/variable/fontsize.scss';
@import '../../../../src/assets/style/scss/themes-var.module';


.email-img {
  display: flex;
  justify-content: center;
  width: #{$size235}px;
  height: #{$size235}px;
  margin: 0 auto;
}

.content-information {
  text-align: center;
  margin-top: #{$size20}px;
}

.content-infomation-header {
  text-align: center;
}


