//font-size
$size1: 1;
$size2: 2;
$size3: 3;
$size4: 4;
$size5: 5;
$size6: 6;
$size7: 7;
$size8: 8;
$size9: 9;
$size10: 10;
$size11: 11;
$size12: 12;
$size13: 13;
$size14: 14;
$size15: 15;
$size16: 16;
$size17: 17;
$size18: 18;
$size19: 19;
$size20: 20;
$size21: 21;
$size22: 22;
$size23: 23;
$size24: 24;
$size25: 25;
$size26: 26;
$size27: 27;
$size28: 28;
$size29: 29;
$size30: 30;
$size31: 31;
$size32: 32;
$size33: 33;
$size34: 34;
$size35: 35;
$size36: 36;
$size37: 37;
$size38: 38;
$size39: 39;
$size40: 40;
$size41: 41;
$size42: 42;
$size43: 43;
$size44: 44;
$size45: 45;
$size46: 46;
$size47: 47;
$size48: 48;
$size49: 49;
$size50: 50;
$size51: 51;
$size52: 52;
$size53: 53;
$size54: 54;
$size55: 55;
$size56: 56;
$size57: 57;
$size58: 58;
$size59: 59;
$size60: 60;
$size61: 61;
$size62: 62;
$size63: 63;
$size64: 64;
$size65: 65;
$size66: 66;
$size67: 67;
$size68: 68;
$size69: 69;
$size70: 70;
$size71: 71;
$size72: 72;
$size73: 73;
$size74: 74;
$size75: 75;
$size76: 76;
$size77: 77;
$size78: 78;
$size79: 79;
$size80: 80;
$size81: 81;
$size82: 82;
$size83: 83;
$size84: 84;
$size85: 85;
$size86: 86;
$size87: 87;
$size88: 88;
$size89: 89;
$size90: 90;
$size91: 91;
$size92: 92;
$size93: 93;
$size94: 94;
$size95: 95;
$size96: 96;
$size97: 97;
$size98: 98;
$size99: 99;
$size100: 100;
$size101: 101;
$size102: 102;
$size103: 103;
$size104: 104;
$size105: 105;
$size106: 106;
$size107: 107;
$size108: 108;
$size109: 109;
$size110: 110;
$size111: 111;
$size112: 112;
$size113: 113;
$size114: 114;
$size115: 115;
$size116: 116;
$size117: 117;
$size118: 118;
$size119: 119;
$size120: 120;
$size121: 121;
$size122: 122;
$size123: 123;
$size124: 124;
$size125: 125;
$size126: 126;
$size127: 127;
$size128: 128;
$size129: 129;
$size130: 130;
$size131: 131;
$size132: 132;
$size133: 133;
$size134: 134;
$size135: 135;
$size136: 136;
$size137: 137;
$size138: 138;
$size139: 139;
$size140: 140;
$size141: 141;
$size142: 142;
$size143: 143;
$size144: 144;
$size145: 145;
$size146: 146;
$size147: 147;
$size148: 148;
$size149: 149;
$size150: 150;
$size151: 151;
$size152: 152;
$size153: 153;
$size154: 154;
$size155: 155;
$size156: 156;
$size157: 157;
$size158: 158;
$size159: 159;
$size160: 160;
$size161: 161;
$size162: 162;
$size163: 163;
$size164: 164;
$size165: 165;
$size166: 166;
$size167: 167;
$size168: 168;
$size169: 169;
$size170: 170;
$size171: 171;
$size172: 172;
$size173: 173;
$size174: 174;
$size175: 175;
$size176: 176;
$size177: 177;
$size178: 178;
$size179: 179;
$size180: 180;
$size181: 181;
$size182: 182;
$size183: 183;
$size184: 184;
$size185: 185;
$size186: 186;
$size187: 187;
$size188: 188;
$size189: 189;
$size190: 190;
$size191: 191;
$size192: 192;
$size193: 193;
$size194: 194;
$size195: 195;
$size196: 196;
$size197: 197;
$size198: 198;
$size199: 199;
$size200: 200;
$size201: 201;
$size202: 202;
$size203: 203;
$size204: 204;
$size205: 205;
$size206: 206;
$size207: 207;
$size208: 208;
$size209: 209;
$size210: 210;
$size211: 211;
$size212: 212;
$size213: 213;
$size214: 214;
$size215: 215;
$size216: 216;
$size217: 217;
$size218: 218;
$size219: 219;
$size220: 220;
$size221: 221;
$size222: 222;
$size223: 223;
$size224: 224;
$size225: 225;
$size226: 226;
$size227: 227;
$size228: 228;
$size229: 229;
$size230: 230;
$size231: 231;
$size232: 232;
$size233: 233;
$size234: 234;
$size235: 235;
$size236: 236;
$size237: 237;
$size238: 238;
$size239: 239;
$size240: 240;
$size241: 241;
$size242: 242;
$size243: 243;
$size244: 244;
$size245: 245;
$size246: 246;
$size242: 247;
$size248: 248;
$size249: 249;
$size250: 250;
$size251: 251;
$size252: 252;
$size253: 253;
$size254: 254;
$size255: 255;
$size256: 256;
$size257: 257;
$size258: 258;
$size259: 259;
$size260: 260;
$size261: 261;
$size262: 262;
$size263: 263;
$size264: 264;
$size265: 265;
$size266: 266;
$size267: 267;
$size268: 268;
$size269: 269;
$size270: 270;
$size271: 271;
$size272: 272;
$size273: 273;
$size274: 274;
$size275: 275;
$size276: 276;
$size277: 277;
$size278: 278;
$size279: 279;
$size280: 280;
$size281: 281;
$size282: 282;
$size283: 283;
$size284: 284;
$size285: 285;
$size286: 286;
$size287: 287;
$size288: 288;
$size289: 289;
$size290: 290;
$size291: 291;
$size292: 292;
$size293: 293;
$size294: 294;
$size295: 295;
$size296: 296;
$size297: 297;
$size298: 298;
$size299: 299;
$size300: 300;
$size301: 301;
$size301: 302;
$size303: 303;
$size304: 304;
$size304: 305;
$size306: 306;
$size307: 307;
$size308: 308;
$size314: 314;
$size319: 319;
$size335: 335;
$size350: 350;
$size383: 383;
$size384: 384;
$size395: 395;
$size406: 406;
$size442: 442;
$size462: 462;
$size475: 475;
$size478: 478;
$size500: 500;
$size526: 526;
$size550: 550;
$size567: 567;
$size737: 737;
$size896: 896;
$size892: 892;
$size916: 916;
$size938: 938;
$size942: 942;
$size944: 944;
$size990: 990;
$size1056: 1056;
$size1059: 1059;
$size1165: 1165;
$size1167: 1167;

$fontWeigth300: 300;
$fontWeigth400: 400;
$fontWeigth500: 500;
$fontWeigth600: 600;
$fontWeigth700: 700;
$fontWeigth800: 800;
$fontWeigth900: 900;
