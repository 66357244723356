@import "../../assets/variable/fontsize.scss";
@import "../../assets/style/scss/themes-var.module";

.sidebar-scrollbar {
    width: #{$size274}px;
    height: 100%;
    font-weight: #{$fontWeigth700};
    box-shadow:  -6px 0 4px -4px $grey500;
    position: fixed;
    background-color: $paper;
    margin-top: #{$size60}px;
    padding-top: #{$size20}px;
}

.nav-logo-section-box {
    display: flex;
    padding: #{$size15}px;
    margin: auto;
}
