@import "../../../../assets/style/scss/themes-var.module";
@import "../../../../assets/variable/fontsize.scss";


.nav-collapse {
    margin: #{$size8}px 0;
    padding: #{$size8}px #{$size12}px #{$size8}px #{$size12}px;
    &:hover {
        color: $variableOrange;
        background-color: $orangeMenu;
        & .nav-collapse-icon {
            color: $variableOrange;
        }
        & .icon-time-tracking {
            fill: $variableOrange;
            &:last-child {
                margin-left: #{$size3}px;
            }
        }
    }
    
}

.nav-collapse-open {
    margin: #{$size8}px 0;
    padding: #{$size8}px #{$size12}px;
    color: $variableOrange;
    &:focus {
        color: $variableOrange;
        & .nav-collapse-icon {
            color: $variableOrange;
        }
    }
    & .icon-time-tracking {
        fill: $variableOrange;
        &:last-child {
            margin-left: #{$size3}px;
            height: #{$size21}px;
            width: #{$size21}px;
        }
    }
    &:hover {
        background-color: $orangeMenu;
      }
}

.nav-collapse-icon-open {
    color: $variableOrange;
    min-width: #{$size32}px;
    &:focus {
        color: $variableOrange;
        & .nav-collapse-icon {
            color: $variableOrange;
            &:hover {
                color: $variableOrange
            }
        }
    }
    &:hover {
        background-color: $orangeMenu;
    }
}

.nav-collapse-icon {
    min-width: #{$size32}px;
    color: $variableBtnMenuNav;
    &:hover {
        color: $variableOrange;
        fill: $variableOrange;
    }
    & .icon-time-tracking {
        fill: $variableBtnMenuNav;
        &:last-child {
            margin-left: #{$size3}px;
            height: #{$size21}px;
            width: #{$size21}px;
        }
        &:hover {
            fill: $variableOrange;
        }
    }
}

.collapse-menu {
    position: relative;
    margin-top: -#{$size12}px;

    &::after {
        position: absolute;
        left: #{$size32}px;
        height: 100%;
        opacity: 1;
        background: $primaryLight;
    }
    &:focus {
        color: $variableOrange;
        background-color: $orangeMenu;
    }
    &-icon-base {
        margin-top: auto;
        margin-bottom: auto;
        color: $variableButtonDropdown;
    }
}
