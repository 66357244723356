@import '../../../../assets/style/scss/themes-var.module';
@import '../../../../assets/variable/fontsize.scss';
.viewHeader {
  width: 100%;
  height: #{$size36}px;

  display: flex;
  justify-content: space-between;
}

.headerTitle {
  font-size: #{$size30}px;
  font-weight: $fontWeigth700;
  letter-spacing: 1%;
}

.headerButton button {
  display: flex;
  font-size: #{$size14}px;
  border-radius: #{$size5}px;
  padding: 0 #{$size16}px;
}
