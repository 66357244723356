@import '../../../../assets/style/scss/themes-var.module';
@import '../../../../assets/variable/fontsize.scss';
.viewContentProject {
  margin: #{$size34}px 0;
  background: $paper;
  padding: #{$size29}px #{$size24}px;
  border: #{$size2}px solid $variableLight;
  border-radius: #{$size6}px;
}
.style-content {
  padding: #{$size10}px 0;
}
.style-content-manager {
  padding: #{$size10}px 0;
}
// css div

.style-div-manager {
  min-height: #{$size52}px;
  display: flex;
  border: #{$size1}px solid $grey400;
  justify-content: space-between;
  align-items: center;
  border-radius: #{$size4}px;
  margin: #{$size16}px 0;
}
.style-name-manager {
  display: flex;
  align-items: center;
  padding-left: #{$size16}px;
}
//css member
.style-div-member {
  min-height: #{$size52}px;
  display: flex;
  border: #{$size1}px solid $grey400;
  justify-content: space-between;
  align-items: center;
  border-radius: #{$size4}px;
  margin: #{$size16}px 0;
}
.style-name-member {
  display: flex;
  align-items: center;
  padding-left: #{$size16}px;
}
//css chip
.style-chip {
  border-radius: #{$size8}px;
  margin-right: #{$size16}px;
}
// style Typography
.style-title-view {
  color: $variableButtonDropdown;
  font-size: #{$size14}px;
}
.style-data-view {
  color: $variableBtnMenuNav;
  font-size: #{$size14}px;
}
.typographyH6 {
  font-size: #{$size16}px;
  font-weight: $fontWeigth600;
}