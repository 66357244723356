@import "assets/style/scss/_themes-var.module.scss";
@import "assets/variable/fontsize.scss";

.header-left {
    width: #{$size274}px;
}

.header-drawer-toggle-button {
    transition: all .2s ease-in-out;
    background: $secondaryLight;
    color: $secondaryDark;

    &:hover {
        background: $secondaryDark;
        color: $secondaryLight;
    }
}

.header-button-base {
    border-radius: #{$size20}px
}
.box-logo {
    text-align: center;
    background-color: #F8B200;
    width: #{$size274}px;
}
.box-shadow {
    display: flex;
    z-index: 99;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    background-color: #fff;
    position: fixed;
}
.header-right {
    display: flex;
    justify-content: space-between;
    width: 100%;
  }