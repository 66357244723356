@import '../../../assets/style/scss/themes-var.module';
@import '../../../assets/variable/fontsize.scss';

.ant-collapse-header {
  background-color: $paper;
}

.ant-collapse > .ant-collapse-item:last-child > .ant-collapse-header {
  border-radius: #{$size8}px;
}

.container-table {
  display: flex;
  max-height: 20rem;
  overflow: auto;
}
.style-border-chip {
  border-radius: #{$size8}px;
  margin-top: #{$size6}px;
  margin-bottom: #{$size6}px;
}
.style {
  &-button-export {
    background-color: $primary600;
    color: $paper;
    padding: 0 #{$size20}px;
    font-size: #{$size14}px;
    text-transform: none;
    &:hover {
      background-color: $primary600;
      color: $paper;
    }
  }
  &-title-project {
    font-weight: $fontWeigth700;
    font-size: #{$size16}px;
    padding-left: #{$size10}px;
  }
  &-box-title {
    display: flex;
    margin-top: #{$size6}px;
  }
}
.ant-collapse .ant-collapse-content > .ant-collapse-content-box {
  padding: 0;
}
.container-loading {
  height: 60vh;
  display: flex;
  justify-content: center;
  align-self: center;
}
.border-right {
  border-right: #{$size1}px solid $variableOrange;
}

.box {
  &-container {
    width: 75vh;
    height: 14vh;
    background-color: $paper;
    border: #{$size2}px solid $variableLight;
    border-radius: #{$size6}px;
    margin-top: #{$size25}px;
    padding: #{$size10}px #{$size15}px #{$size15}px #{$size15}px;
  }
  &-notes {
    display: flex;
    height: 80%;
    &-left {
      width: 50%;
      display: flex;
      flex-direction: column;
      justify-content: space-around;
    }
    &-right {
      width: 50%;
      display: flex;
      flex-direction: column;
      justify-content: space-around;
    }
  }
  &-text-notes {
    font-size: #{$size18}px;
    font-weight: $fontWeigth700;
    color: $variableBlack;
  } 
  &-flex {
    display: flex;
    
  }
}
