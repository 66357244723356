@import '../../../assets/style/scss/themes-var.module';
@import '../../../assets/variable/fontsize.scss';

.viewContainer {
  width: 100%;
  margin: #{$size10}px 0;
}
.container-loading {
  height: #{$size80}vh;
  justify-content: center;
  align-self: center;
}