@import '../../../../assets/style/scss/themes-var.module';
@import '../../../../assets/variable/fontsize.scss';
.list-users {
  position: absolute;
  background-color: $paper;
  width: #{$size500}px;
  box-shadow: 0px #{$size1}px #{$size2}px #{$darkSearch}, 0px #{$size2}px #{$size6}px #{$size2}px #{$darkSearch};
  border-radius: #{$size4}px;
}

.user {
  display: flex;
  align-items: center;
}
.user:hover{
  background-color: #{$grey300};
  transition: 0.5s;
}

.style-link-view {
  color: #{$variableBlack};
  text-decoration: none;
}
.action-clear-input{
  margin-top: #{$size6}px;
  cursor: pointer;
}

