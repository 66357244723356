@import '../../../src/assets/variable/fontsize.scss';
@import '../../../src/assets/style/scss/themes-var.module';

.login-page {
  background-image: url('../../assets/img/BackGround.png');
  height: #{$size100}vh;
  background-repeat: no-repeat;
  background-size: cover;
}

.login-column {
  width: 50%;
  height: #{$size100}vh;
  display: flex;
  justify-content: center;
  background-color: $paper;
}

.login-container {
  max-width: #{$size475}px;
  height: #{$size100}vh;
  margin-top: 10%;
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  align-self: center;
  justify-content: center;
}

.login-logo-container {
  margin-bottom: #{$size20}px;
  text-align: center;
}

.login-information {
  margin: 12% 0 8% 0;
}
.login-icon-input {
  position: absolute;
  right: 2%;
}
.login-input-props {
  padding-right: #{$size60}px;
}

.forgot-password-link {
  color: $variableButtonDropdown;
  font-size: #{$size16}px;
}

.login-copyright {
  position: fixed;
  width: #{$size475}px;
  bottom: #{$size12}px;
  z-index: #{$size10};
  text-align: center;
  margin: 0 auto;
  &-text {
    font-size: #{$size14}px;
    font-weight: $fontWeigth400;
    color: $variableButtonDropdown;
  }
}
.login-logo-vatek {
  position: absolute;
  right: 2%;
  top: 5%;
}
.inputTextfield {
  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  input:-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0 #{$size30}px $paper inset !important;
  }
}
.login-logo {
  position: absolute;
  right: 13%;
  top: 20%;
}

.login-icon-vatek {
  padding: #{$size30}px #{$size30}px 0 0;
  text-align: end;
}

.login-banner {
  &-box {
    display: flex;
    justify-content: center;
    align-items: center;
    height: #{$size85}vh;
  }
  &-img {
    display: flex;
    justify-content: center;
    align-self: center;
  }
}
.button-submit {
  text-transform: capitalize;
  font-size: #{$size14}px;
  font-weight: $fontWeigth700;
  background-color: $variableBackgroundStep;
  margin-top: #{$size70}px;
  &:hover {
    background-color: $variableBackgroundStep;
  }
}

@media only screen and (max-width: 1200px) {
  .login-column {
    width: 65%;
  }
  .login-logo {
    right: 3%;
  }
}

@media only screen and (max-width: 1600px) {
  .login-logo {
    top: 16%;
    right: 10%;
  }

}
@media only screen and (max-width: 1600px), (max-height: 800px) {
  .login-information {
    margin-top: 2%;
  }
}
