@import '../../../assets/variable/fontsize.scss';
@import '../../../assets/style/scss/themes-var.module';

.box-total {
  display: flex;
  &-title {
    display: flex;
    border-bottom: #{$size1}px solid $grey350;
    &-text {
      font-size: #{$size18}px;
      padding: #{$size5}px #{$size14}px;
      font-weight: #{$fontWeigth700};
    }
  }
  &-content {
    height: #{$size80}px;
    padding: #{$size4}px #{$size18}px;
    &-length {
      font-size: #{$size28}px;
      font-weight: #{$fontWeigth700};
      padding-bottom: #{$size4}px;
    }
    &-name {
      font-size: #{$size14}px;
      font-weight: #{$fontWeigth400};
      color: #949494;
    }
  }
  &-container {
    background-color: $paper;
    border-radius: #{$size6}px;
    border: #{$size2}px solid $grey350;
  }
}
