@import '../../../../assets/style/scss/themes-var.module';
@import '../../../../assets/variable/fontsize.scss';

.body-content {
  width: 100%;
  height: #{$size80}vh;
  margin-top: #{$size34}px;
  display: flex;
}

.container-content {
  background-color: $paper;
  padding: #{$size32}px 0;
  border: #{$size2}px solid $variableLight;
  border-radius: #{$size6}px;
  display: flex;
  overflow: auto;
  &::-webkit-scrollbar {
    width: 0px;
  }
}

.container-content-left {
  width: 15%;
  text-align: center;
}
.style-img {
  width: #{$size110}px;
  height: #{$size110}px;
  border-radius: #{$size200}px;
  object-fit: cover;
  margin-bottom: #{$size32}px;
}
.container-content-right {
  width: 85%;
  padding: 0 #{$size24}px 0 #{$size15}px;
}
.style-typography {
  font-size: #{$size16}px;
  font-weight: $fontWeigth700;
}
.employment-details {
  margin-top: #{$size15}px;
}
.style-box-btn {
  display: flex;
  padding: #{$size32}px 0;
  justify-content: flex-end;
}
.btn-cancel {
  width: #{$size75}px;
  height: #{$size33}px;
  border: #{$size1}px solid $variableButtonDropdown;
  color: $variableButtonDropdown;
  margin-right: #{$size25}px;
  border-radius: #{$size5}px;
  font-size: #{$size14}px;
  font-weight: $fontWeigth700;
  text-transform: capitalize;
  text-decoration: none;
}
.btn-save {
  width: #{$size62}px;
  height: #{$size33}px;
  background: $variableBackgroundStep;
  color: $paper;
  font-weight: $fontWeigth700;
  font-size: #{$size14}px;
  border-radius: #{$size5}px;
  text-transform: capitalize;
  &:hover {
    background-color: $variableBackgroundStep;
  }
}

.avatar-form-header {
  text-align: center;
  border-bottom: #{$size1}px solid $grey300;
  width: #{$size300}px;
}
.avatar-form-container {
  display: flex;
  justify-content: center;
  margin-top: #{$size20}px;
}
.avatar-preview,.avatar-upload-btn {
  width: #{$size200}px;
  height: #{$size200}px;
  border-radius: 50%;
  cursor: pointer;
}
.avatar-upload-btn {
  button {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: #{$size18}px;
    border: none;
    color: $variableBlack;
  }
}

.Toastify__toast-container--top-center {
  top: 50% !important;
  left: 50% !important;
  transform: translate(-50%, -50%) !important;
  padding: 0 !important;
  // min-width: 500px !important;
  height: 100vh;
  width: 100vw !important;
  .Toastify__toast-theme--light {
    background-color: rgba(163, 163, 163, 0.2) !important;
  }
  button {
    display: none;
  }
  .Toastify__toast  {
    padding: 0;
  }
  .Toastify__toast-body  {
    padding: 0;
  }
}

