@import '../../assets/style/scss/themes-var.module';
@import '../../assets/variable/fontsize.scss';


.detail-popup {
    text-align: center;
    display: flex;
    width: #{$size100}vw;
    height: #{$size100}vh;
    background-color: $darkSearch;
    // padding: 10px;
    .main-popup {
        margin: auto;
        border-radius: #{$size10}px;
        width: #{$size442}px;
        background-color: $paper;
        padding: #{$size5}px;
    }
    .header {
        padding: #{$size10}px;
        border-bottom: #{$size1}px solid $grey400;
        .type {
            font-size: 1.8rem;
            font-weight: bolder;
            margin-bottom: #{$size10}px;
            color: $errorMain;
        }
    }
    .body {
        padding: #{$size20}px #{$size5}px #{$size20}px #{$size5}px;
        border-bottom-right-radius: #{$size5}px;
        border-bottom-left-radius: #{$size5}px;
        .icon {
            font-size: #{$size5}rem;
            color: $errorMain;
            margin-bottom: #{$size12}px;
        }
        .content {
            font-size: #{$size1}rem;
            color: $grey700;
        }
       
    }
}