@import '../../assets/variable/fontsize.scss';
@import 'assets/style/scss/themes-var.module';

.leave-calendar-table {
  border: #{$size2}px solid $variableLight;
  background: $paper;
  border-radius: #{$size4}px;
  height: auto;
  padding: #{$size26}px #{$size50}px;
  margin-top: #{$size10}px;
}

.leave-calendar-view {
  width: 100%;
  height: #{$size100}vh;
  margin: auto;
}
.add-leave-btn {
  display: flex;
  margin-bottom: #{$size20}px;
  justify-content: flex-end;
}
.fc-day-sun {
  background-color: $grey50;
  opacity: 0.4;
}
.fc-day-sat {
  background-color: $grey50;
  opacity: 0.4;
}
.fc--button .fc-button .fc-button-primary {
  display: none;
}
.fc-toolbar-chunk > div {
  display: flex;
}
.fc .fc-button-primary {
  padding: #{$size6}px #{$size15}px;
  background: $paper;
  border-color: $variableLight;
  color: $variableBlack;
  border-radius: #{$size5}px;
  &:hover {
    background: $variableBackgroundStep;
    border-color: $variableOrange;
    &:focus {
      background: $variableBackgroundStep;
      border: #{$size1}px solid $variableOrange;
    }
  }
}
.fc-toolbar-chunk > div > h2 {
  padding: 0 #{$size15}px;
}
.fc .fc-addLeave-button {
  background: $variableBackgroundStep;
  color: $paper;
  &:hover {
    background: $variableOrange;
    border-color: $variableOrange;
    &:focus {
      background-color: $variableOrange;
      border: #{$size1}px solid $variableOrange;
    }
  }
}

.leave-calendar-note {
  width: 100%;
  margin-top: #{$size10}px;
  background: #{$paper};
  border-radius: #{$size10}px;
  padding-top: #{$size10}px;
}
.text-note {
  margin-left: #{$size20}px;
  font-weight: #{$fontWeigth700};
  font-size: #{$size18}px;
}
.leave-calendar-button {
  margin-top: #{$size12}px;
}
.leave-calendar-other {
  padding-bottom: #{$size13}px;
}
.leave-calendar-fullDay,
.leave-calendar-other {
  display: flex;
  align-items: center;
  margin-bottom: #{$size10}px;
}
.leave-calendar-button-fullDay {
  background-color: #{$buttonPending};
}
.leave-calendar-button-other {
  background-color: #{$buttonApproved};
}

.leave-calendar-button-fullDay,
.leave-calendar-button-other {
  width: #{$size38}px;
  height: #{$size10}px;
  margin-left: #{$size20}px;
  border-radius: #{$size10}px;
  cursor: default;
}

.leave-calendar-fullDay-text,
.leave-calendar-other-text {
  margin-left: #{$size17}px;
  font-weight: #{fontWeigth400};
  font-size: #{$size16}px;
}
.leave-calendar-button-fullDay:hover {
  background-color: #{$buttonPending};
}
.leave-calendar-button-other:hover {
  background-color: #{$buttonApproved};
}
