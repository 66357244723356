@import '../../../../src/assets/variable/fontsize.scss';
@import '../../../../src/assets/style/scss/themes-var.module';

.change-password-information {
  margin: 12% 0 8% 0;
  max-width: #{$size475}px;
  margin: 0 auto;
}

.change-password-form {
  margin-top: #{$size20}px;
}

.change-password-icon-input {
  position: absolute;
  right: 2%;
}

.change-password-input-props {
  padding-right: #{$size60}px;
}

.inputTextfield {
  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  input:-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0 #{$size30}px $paper inset !important;
  }
}

.button-submit {
  text-transform: capitalize;
  font-size: #{$size14}px;
  font-weight: $fontWeigth700;
  background-color: $variableBackgroundStep;
  margin-top: #{$size35}px;
  &:hover {
    background-color: $variableBackgroundStep;
  }
}

