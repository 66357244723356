@import '../../assets/style/scss/themes-var.module';
@import '../../assets/variable/fontsize.scss';

$background: $paper;

.container-stack {
  border: 2px solid $variableLight;
  background: $paper;
  border-radius: #{$size4};
  max-height: #{$size80}vh;
}

.item-box-timesheet {
  max-height: #{$size90}px;
  margin-bottom: #{$size6}px;
  border: #{$size1}px solid $primaryLight100;
  border-radius: #{$size5}px;
}

.buttonTime {
  background: $background;
  margin-left: #{$size10}px;
  margin-right: #{$size10}px;
}

.buttonShowToday {
  background: $variableBackgroundStep;
  gap: #{$size6}px;
  text-transform: 'none';
  margin-left: #{$size10}px;
  &:hover {
    background: $variableBackgroundStep;
  }
}

.buttonText {
  // font-family: 'Product Sans';
  font-style: normal;
  font-weight: $fontWeigth400;
  font-size: #{$size18}px;
  line-height: #{$size23}px;

  letter-spacing: 0.01em;

  color: $variableBlack;

  flex: none;
  order: 0;
  flex-grow: 0;
}

.button-add {
  height: #{$size47}px;
  width: #{$size130}px;
  background: $variableBackgroundStep;
  gap: #{$size6}px;
  text-transform: none;
  .p {
    color: $paper;
    font-style: normal;
    font-size: #{$size14}px;
    font-weight: $fontWeigth700;
  }
  &:hover {
    background: $variableBackgroundStep;
  }
}

.buttonTextTitle {
  // font-family: 'Product Sans';
  font-style: normal;
  font-weight: $fontWeigth700;
  font-size: #{$size14}px;
  line-height: #{$size23}px;
  text-align: center;
  letter-spacing: 0.01em;
  text-transform: none;

  flex: none;
  order: 0;
  flex-grow: 0;
}

.buttonTextTitle-color-black {
  color: $variableBlack;
  @extend .buttonTextTitle;
}

.text-title-date-color-black {
  color: $variableBlack;
  @extend .buttonTextTitle;
  font-weight: $fontWeigth400;
}
.buttonTextTitle-color-paper {
  color: $paper;
  @extend .buttonTextTitle;
}

.status-title {
  border-top-left-radius: #{$size6}px;
  border-top-right-radius: #{$size6}px;
}

.buttonTextTitle-child {
  // font-family: Product Sans;
  font-style: normal;
  font-weight: $fontWeigth700;
  font-size: #{$size16}px;
  line-height: #{$size23}px;
  letter-spacing: 0.01em;

  color: $variableBtnMenuNav;

  flex: none;
  order: 0;
  flex-grow: 0;

  @extend .auto-short-content;
}
.buttonTextTitle-child-center {
  text-align: center;

  @extend .buttonTextTitle-child;
  // font-family: Product Sans;
  font-size: #{$size12}px;
  font-weight: $fontWeigth400;
}
.buttonTextTitle-child-justify {
  text-align: justify;
  color: $variableButtonDropdown;

  @extend .buttonTextTitle-child;
}
.box-status {
  font-weight: $fontWeigth400;
  font-size: #{$size12}px;
  line-height: #{$size23}px;
  // color: #009D10;
  flex: none;
  order: #{$size1};
  text-align: justify;
  flex-grow: 0;
}
.buttonTextTitle-child-description {
  // font-family: 'Product Sans';
  font-style: normal;
  font-weight: $fontWeigth400;
  font-size: #{$size14}px;
  line-height: #{$size21}px;
  text-align: justify;
  letter-spacing: 0.01em;

  color: $variableBtnMenuNav;

  flex: none;
  order: 0;
  flex-grow: 0;

  @extend .auto-short-content;
}
.auto-short-content {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
.textTitle-work-name {
  font-style: normal;
  font-weight: $fontWeigth700;
  font-size: #{$size16}px;
  line-height: #{$size23}px;
  text-align: center;

  display: flex;

  color: $variableButtonDropdown;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;

  flex: none;
  @extend .auto-short-content;
}

.MuiTableRow-root td:first-child {
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}

.MuiTableRow-root td:last-child {
  border-top-right-radius: 10px;
  border-top-right-radius: 10px;
}

.button {
  width: #{$size55}px;
  height: #{$size47}px;
  display: flex;
  align-items: center;
  background: $background;
  border-radius: #{$size5}px;
  &:hover {
    background: $paper;
  }
}

.title-page {
  font-weight: $fontWeigth700;
  font-size: #{$size30}px;
  margin: #{$size18}px 0;
}

.style-button-popup {
  padding-bottom: #{$size32}px;
  padding-right: #{$size24}px;
}
