@import '../../../assets/variable/fontsize.scss';
@import '../../../assets/style/scss/themes-var.module';

.box-project {
  border: #{$size2}px solid $grey350;
  background-color: $paper;
  border-radius: #{$size6}px;
  margin-top: #{$size24}px;
}
.box-tablecontainer-search {
  height: #{$size62}px;
  padding: 0 #{$size18}px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: #{$size2}px solid $variableLight;
}
.box-tablecontainer-iconfilter {
  margin-left: #{$size18}px;
}
.box-tablecontainer-add {
  padding: #{$size8}px #{$size16}px;
  font-size: #{$size14}px;
  font-weight: #{$fontWeigth700};
  background-color: $variableBackgroundStep;
  color: $paper;
  border-radius: #{$size6}px;
  text-transform: capitalize;
  &:hover {
    background-color: $variableBackgroundStep;
  }
}
.box-table-head {
  background: rgba(0, 0, 0, 0.05);
  &:nth-child(2) {
    text-align: left;
  }
}
.project-title {
  text-align: center;
}
.project-title-name {
  font-weight: #{$fontWeigth600};
}
.title-project {
  text-align: justify;
  font-weight: #{$fontWeigth600};
  &:first-child {
    text-align: left;
  }
}
.style-button-add {
  text-decoration: none;
}