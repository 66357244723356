@import '../../../assets/variable/fontsize.scss';

.dashboard-item-container {
  padding: #{$size16}px #{$size24}px;
}
.dashboard-event {
  padding-bottom: #{$size16}px;
}

.dashboard-on-event-user-avatar {
  margin-right: #{$size10}px;
}
.dashboard-event-name {
  font-size: #{$size1}rem;
  margin-top: #{$size16}px;
  text-align: center;
  font-weight: #{$fontWeigth600};
}
.dashboard-on-event-userName {
    font-size: #{$size1}rem;
    font-weight: #{$fontWeigth700};
}
.dashboard-on-event-brithday {
    font-size: #{$size1}rem;

}
.dashboard-on-event-user {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: #{$size50}px;
}
.dashboard-on-event-user-content {
  display: flex;
  align-items: center;
}
.box-loading {
  height: #{$size21}vh;
  display: flex;
  justify-content: center;
  align-self: center;
}